import { Col, Container, Row } from "react-bootstrap";


const Main = () => {

    return(
        <div className="main-container">
            <Container fluid className="p-0 vh-100 w-100 position-relative overlay overflow-hidden">
                <Row>
                    <Col className="p-0">
                        <video loop autoPlay playsInline muted>
                            <source src="/videos/bgVid.mp4" type="video/mp4" />
                        </video>
                    </Col>
                </Row>
            </Container>
            <Container className="text-overlay">
            <Row>
              <h1 className="display-1">SILICON45</h1>
            </Row>
            <Row className="justify-content-center">
              <Col sm={6}>
                <h4>Heterogeneous computing to unleash the power of your transformative AI application</h4>
              </Col>
            </Row>
          </Container>
          <div className="h-100 w-100 top-0 start-0 opacity-50 bg-dark overlay overflow-hidden" />
        </div>
    )
}

export default Main;
