
const LoadingScreen = () => {

    return (
        <div className="container">
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <img src="/loader/si45-loader.svg" alt="Loading" width="100" height="100" />
            </div>
        </div>
      );
}

export default LoadingScreen;