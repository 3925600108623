import { useEffect, useState } from 'react';
import { fetchJobs } from '../services/jobs';
export default function useJobs() {
    const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchJobs();
        console.log('data', data)
        setJobs(data);
      } catch (e) {
        console.error(e)
      }
    }
    fetchData();
  }, []);

  return { jobs }
}