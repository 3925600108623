import React from 'react';
import './scss/style.scss';
import Main from "./components/views/Main/Main";
import { Container } from 'react-bootstrap';
import Header from './components/views/Header/Header';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import JobBoard from './components/views/Main/JobBoard/JobBoard';
import ContactForm from './components/views/Main/ContactForm/ContactForm';

function App() {
  
  return (
    <Router>
      <Container fluid className="p-0 m-0">
        <Header />
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/careers" element={<JobBoard />} />
          <Route path="/contact" element={<ContactForm />} />
        </Routes>
      </Container>
    </Router>
  );
}

export default App;
