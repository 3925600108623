import { Container, Nav, Navbar } from "react-bootstrap"
import { Link } from "react-router-dom";

const Header = () => {

    return (
      <Navbar expand="lg" className="bg-dark">
      <Container fluid className="mx-0">
        <Navbar.Brand as={Link} to="/">
          <h3 className="mb-0 ps-2 text-light">
            SILICON45
          </h3>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/careers">
              <h5 className="mb-0 text-info">
                Careers
              </h5>
            </Nav.Link>
            <Nav.Link as={Link} to="/contact">
              <h5 className="mb-0 text-info">
                Contact Us
              </h5>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default Header;
