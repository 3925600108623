import axios from "axios";

const apiURL = process.env.REACT_APP_BASE_URL;

export const fetchJobs = async () => {
    try {
        const res = await axios.get(apiURL + '/api/jobs/');
        console.log('res', res.data)
        return res.data;
    } catch (error) {
        throw new Error(error);
    }
}