import axios from "axios";

const apiURL = process.env.REACT_APP_BASE_URL;


const generateToken = async () => {
    try {
      const response = await axios.post(apiURL + 'api/generate_token', {
      });
  
      if (response.status === 200) {
        return response.data.token; 
      } else {
        throw new Error('Failed to generate token');
      }
    } catch (error) {
      console.error('Error generating token:', error);
      throw error;
    }
  };
  
  export { generateToken };
  